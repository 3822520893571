import classNames from "classnames";
import React from "react";
import Game from "./Game";

const App = () => {
  return (
    <main
      className={classNames(
        "w-screen",
        "snap-y snap-mandatory overflow-y-scroll h-screen"
      )}
    >
      <section className={classNames("relative", "h-screen snap-start")}>
        <header className="absolute z-10 top-6 right-6">
          <img src="/assets/logo.png" alt="logo" className="w-80" />
        </header>
        <img
          src="/assets/background1.png"
          alt="background"
          className="w-full absolute h-full"
        />
        <div className="z-10 flex flex-col gap-4 justify-center items-center h-screen absolute top-[84%] left-1/2 -translate-y-1/2 -translate-x-1/2">
          <div className="w-8 h-14 border-4 border-white rounded-full shadow-xl flex justify-center">
            <div className="mt-3 w-1 h-4 shadow-lg bg-white rounded-full animate-bounce"></div>
          </div>
          <span className="text-2xl text-white font-extrabold inset-0 text-transparent bg-clip-text filter drop-shadow-[0_0_1px_black]">
            {/* 스크롤하여 이야기를 시작해주세요 */}
          </span>
        </div>
      </section>
      <section
        className={classNames(
          "w-full h-[100vh] flex items-center justify-center bg-black flex-col gap-20",
          "h-screen snap-start background-filtering"
        )}
      >
        <iframe
          title="youtube video"
          width=""
          height="100vh"
          className="w-[60%] h-[60%]"
          src="https://www.youtube.com/embed/CHpaNNaJjwM"
        ></iframe>
        <hgroup className="flex items-center gap-6 background">
          <button className="border-none rounded-full py-3 px-8 flex items-center gap-3 bg-white">
            <img
              className="w-7"
              src="/assets/appstore.png"
              alt="앱스토어 다운로드"
            />
            <span className="text-[#19A6F7] font-extrabold text-xl">
              앱스토어 다운로드
            </span>
          </button>
          <button className="border-none rounded-full py-3 px-8 flex items-center gap-3 bg-white">
            <img
              className="w-7"
              src="/assets/googleplay.jpg"
              alt="플레이 스토어 다운로드"
            />
            <span className="text-[#5C6065] font-extrabold text-xl">
              플레이 스토어 다운로드
            </span>
          </button>
        </hgroup>
      </section>
      <section className={classNames("relative", "h-screen snap-start")}>
        <img className="absolute" src="/assets/yard.jpg" />
        <Game />
      </section>
    </main>
  );
};

export default App;

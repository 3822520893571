import classNames from "classnames";
import React, { useEffect, useState } from "react";

const Game = () => {
  const [animate, setAnimate] = useState(false);

  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount((prev) => prev + 1);

    if (animate) return;
    setAnimate(true);

    setTimeout(() => {
      setAnimate(false);
    }, 150);
  };

  return (
    <div className="absolute w-full h-full flex items-center justify-center">
      {animate && (
        <img
          src="/assets/hit.png"
          alt="hit!"
          className="w-[16vw] fixed z-20"
          style={{
            top: `${
              300 + Math.round(Math.random() * (window.outerHeight - 600))
            }px`,
            left: `${
              300 + Math.round(Math.random() * (window.outerWidth - 600))
            }px`,
          }}
        />
      )}
      <button
        onClick={handleClick}
        className="w-full h-full flex items-center justify-center rainbow-bg flex-col gap-10"
      >
        <h1
          className={classNames("text-9xl font-black text-white", {
            "hitted-text": animate,
          })}
        >
          {count} hit!
        </h1>
        <img
          className={classNames("w-[34vw] h-fit selection:bg-none", {
            hitted: animate,
            "red-filter": animate,
          })}
          src={animate ? "/assets/slime_chewing.png" : "/assets/slime.png"}
          alt="monster"
        />
      </button>
    </div>
  );
};

export default Game;
